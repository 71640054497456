import { Switch,Route } from 'react-router-dom';
import './App.css';
import { AboutPage } from './components/About-Page/AboutPage';
import { CareerPage } from './components/Career-Page/CareerPage';
import { ContactPage } from './components/Contact-Page/ContactPage';
import { MenuBar } from './components/Header/MenuBar';
import { HomePage } from './components/Home-Page/HomePage';
import { ProSerPage } from './components/Pro-Ser-Page/ProSerPage';
import { Footer } from './components/Footer/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { JobOpenings } from './components/Career-Page/JobOpenings';
import { Mno } from './components/Mno';
import ApplyOnline from './components/Career-Page/ApplyOnline';
import ScrollToTop from './components/ScrollToTop';
import { HashLink as Link } from 'react-router-hash-link';


function App() {
  useEffect(() => {
    AOS.init({
      disable: 'mobile',
      duration : 2000,
      once: true,
      mirror: false
    });
  }, []);

  return (
    <div className="App">
      <MenuBar />
      <ScrollToTop />
      <Switch>
        <Route exact path={'/'}>
          <HomePage />
        </Route>
        <Route path={'/about'}>
          <AboutPage />
        </Route>
        <Route path={'/foot-prints'}>
          <ProSerPage />
        </Route>
        <Route path={'/careers'}>
          <CareerPage />
        </Route>
        <Route path={'/contact'}>
          <ContactPage />
        </Route>
        <Route path={'/job-openings'}>
          <JobOpenings />
        </Route>
        <Route path={'/apply-online'}>
          <ApplyOnline />
        </Route>
      </Switch>
      <Footer />
    </div>
  );
}
export default App;
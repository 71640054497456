import React, { useEffect, useState } from "react";
import "./Contact.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

//Bootstrap Grid Components

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Material Ui Form
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

//Material Ui Icons
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import FollowTheSignsSharpIcon from "@mui/icons-material/FollowTheSignsSharp";
import { Grid, Stack, useStepContext } from "@mui/material";
import { styled } from "@mui/material/styles";
import AOS from "aos";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const StyledTextField = styled(TextField)(() => ({
  width: "448px",
  "& label.Mui-focused": {
    color: "#00695f",
  },
  //   '& .MuiInput-underline:after': {
  //     borderBottomColor: 'green',
  //   },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid #00695f",
    },
    "&:hover fieldset": {
      borderColor: "#00695f",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#00695f",
    },
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ContactPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [contactForm, setContactForm] = useState(false);
  const [open, setOpen] = useState(false);

  const onContactFormSubmit = (e) => {
    if (name == "" || email == "" || message == "") {
      setContactForm(true);
      setOpen(true);
    } else {
      e.preventDefault();
      let payload = { Name: name, Email: email, Message: message };
      axios.post("http://localhost:8000/contact", payload).then((res) => {
        setName("");
        setEmail("");
        setMessage("");
        setContactForm(false);
        setOpen(true);
      });
    }
  };
  useEffect(() => {
    AOS.init({
      disable: "mobile",
    });
  }, []);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div
          style={{
            height: "350px",
            marginBottom: "100px",
            position: "relative",
          }}
        >
          <img
            src="https://wallpaperaccess.com/full/3124540.jpg"
            alt="Contact"
            width="100%"
            height="100%"
          />
          <div className="headingTitle">
            <h1 style={{ fontSize: "76px", textAlign: "center", margin: 0 }}>
              Contact
            </h1>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "100px" }}>
        <Container>
          <Grid container justifyContent={"space-between"} rowGap={"50px"}>
            <Grid item xs={12} md={5.5} data-aos="fade-right">
              <img
                src="https://images.unsplash.com/photo-1598257006458-087169a1f08d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                alt="Contact Form"
                className="gridImg"
                width="100%"
                height="100%"
                style={{ borderRadius: "50%" }}
              />
            </Grid>

            <Grid item xs={12} md={5.5} data-aos="fade-left">
              <form onSubmit={onContactFormSubmit}>
                <p className="contactLabel" for="contact">
                  CONTACT US
                </p>
                <div>
                  <StyledTextField
                    id="outlined-textarea"
                    label="Enter Your Name"
                    placeholder="Name"
                    className="controlForm"
                    required
                    name={name}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoComplete={"off"}
                  />

                  <StyledTextField
                    id="outlined-textarea"
                    label="Enter Your Email Id"
                    placeholder="Email id"
                    className="controlForm"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete={"off"}
                  />
                  <StyledTextField
                    id="outlined-multiline-static"
                    label="Enter Your Message"
                    placeholder="Message"
                    multiline
                    rows={5}
                    defaultValue=""
                    className="controlForm"
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    autoComplete={"off"}
                  />
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  className="butOnSubmit"
                >
                  Submit
                </Button>
              </form>
              {!contactForm && (
                <Snackbar
                  open={open}
                  autoHideDuration={3000}
                  onClose={handleClose}
                >
                  <Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    Successfully Submitted!
                  </Alert>
                </Snackbar>
              )}
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid item xs={12}>
        <Container>
          <Grid container justifyContent={"space-between"} rowGap={"50px"}>
            <Grid item xs={12} md={8.5}>
              <Grid container gap={"25px"}>
                <Grid
                  item
                  xs={12}
                  md={5}
                  className={"contactBoxes"}
                  data-aos="zoom-in-up"
                >
                  <p className="topCls">
                    <FollowTheSignsSharpIcon sx={{ fontSize: 50 }} />
                  </p>
                  <p className="midCls">Follow us.</p>
                  <p className="botCls" style={{ cursor: "pointer" }}>
                    <Stack
                      Container
                      direction={"row"}
                      sx={{
                        color: "white",
                        alignItems: "center",
                        columnGap: "17px",
                      }}
                    >
                      <a
                        href="https://www.linkedin.com/company/mspand-technologies-private-ltd/"
                        className="smLink"
                        target="_blank"
                      >
                        <LinkedInIcon sx={{ cursor: "pointer" }} />
                      </a>
                      <a
                        href="https://www.facebook.com/profile.php?id=100010016111639"
                        className="smLink"
                        target="_blank"
                      >
                        <FacebookIcon sx={{ cursor: "pointer" }} />
                      </a>
                      <a
                        href="https://www.instagram.com/mspandtech/"
                        className="smLink"
                        target="_blank"
                      >
                        <InstagramIcon sx={{ cursor: "pointer" }} />
                      </a>
                      <a
                        href=" https://twitter.com/mspand4"
                        className="smLink"
                        target="_blank"
                      >
                        <TwitterIcon sx={{ cursor: "pointer" }} />
                      </a>
                    </Stack>
                  </p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5}
                  className={"contactBoxes"}
                  data-aos="zoom-in-up"
                >
                  <p className="topCls">
                    <LocationOnOutlinedIcon sx={{ fontSize: 50 }} />
                  </p>
                  <p className="midCls">ADDRESS : </p>
                  <p className="botCls">
                    #1023, 2nd Floor, Thangameenatchi Complex, Near Old Bus
                    Stand, Kochi-Madurai-Tondi Point Road, Theni, Tamil Nadu -
                    625531
                  </p>
                  <p className="botCls">
                    Workhub by novel ,DODDANAKUNDI INDUSTRIAL AREA, Graphite
                    India Main Rd, Whitefield, KEB Colony 2nd floor, Industrial
                    Area, Mahadevapura, Bengaluru, Karnataka 560048.
                  </p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5}
                  className={"contactBoxes"}
                  data-aos="zoom-in-up"
                >
                  <p className="topCls">
                    <EmailOutlinedIcon sx={{ fontSize: 50 }} />
                  </p>
                  <p className="midCls">EMAIL : </p>
                  <p className="botCls">contact@mspand.com</p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5}
                  className={"contactBoxes"}
                  data-aos="zoom-in-up"
                >
                  <p className="topCls">
                    <LocalPhoneOutlinedIcon sx={{ fontSize: 50 }} />
                  </p>
                  <p className="midCls">CALL US : </p>
                  <div className="botCls">
                    <p style={{ padding: 0, margin: 0 }}>+91 97878 60208</p>
                    <p>+91 97878 60209</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={3.5}
              display={{ xs: "none", md: "flex" }}
              data-aos="slide-left"
              data-aos-duration="1000"
            >
              <img
                src="https://freepngimg.com/save/147846-professional-woman-business-png-file-hd/801x1023"
                alt=""
                width="80%"
                height="560px"
                className="flexBoxImg"
              />
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid item xs={12}>
        <Container>
          <Grid container justifyContent={"center"}>
            <Grid item xs={12} md={8} data-aos="zoom-in-up">
              <iframe
                title={"map"}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.083746432076!2d77.47401141428206!3d10.009941175610724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0714a5f8944ec5%3A0x6a69b875d13ef5c7!2sMSPAND%20Technologies%20Private%20Ltd!5e0!3m2!1sen!2sin!4v1677827719035!5m2!1sen!2sin"
                className={"googleMap"}
                allowFullScreen={""}
                loading={"lazy"}
                referrerPolicy={"no-referrer-when-downgrade"}
              ></iframe>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>

    // <div>
    //   <div className='contactBackgroundImg'>
    //     <img
    //       src="https://wallpaperaccess.com/full/3124540.jpg"
    //       alt="Contact"
    //       width="100%"
    //       height="100%"
    //     />
    //   </div>
    //     <Box
    //       component="form"
    //       sx={{
    //         '& .MuiTextField-root': { m: 1, width: '25ch' },
    //       }}
    //       noValidate
    //       autoComplete="off"
    //     />

    //       <Container>
    //         <Row>
    //           <Col className="contactGrid" xs={12} md={6} sm={12}>
    //             <img
    //               src="https://images.unsplash.com/photo-1598257006458-087169a1f08d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
    //               alt="Contact Form"
    //               className='gridImg'
    //             />
    //           </Col>
    //           <Col className="contactGrid" xs={12} md={6} sm={12}>
    //             <div className='gridB'>
    //               <form>
    //                 <p className='contactLabel' for="contact">CONTACT US</p>
    //                 <div>
    //                   <TextField
    //                     id="outlined-textarea"
    //                     label="Enter Your Name "
    //                     placeholder="Name"
    //                     multiline
    //                     className='controlForm'
    //                   />

    //                   <TextField
    //                     id="outlined-textarea"
    //                     label="Enter Your Email Id"
    //                     placeholder="Email id"
    //                     multiline
    //                     className='controlForm'
    //                   />
    //                   <TextField
    //                     id="outlined-multiline-static"
    //                     label="Enter Your Message"
    //                     placeholder="Message"
    //                     multiline
    //                     rows={5}
    //                     defaultValue=""
    //                     className='controlForm'
    //                   />
    //                 </div>
    //                 <Button variant="contained" className='butOnSubmit'>Submit</Button>
    //               </form>

    //             </div>

    //           </Col>
    //         </Row>
    //         <Row>
    //           <Col>
    //             <Row>
    //               <Col className="flexBox" xs={12} md={6} sm={12}>
    //                 <div>
    //                   <p className='topCls'>
    //                     <FollowTheSignsSharpIcon
    //                       sx={{ fontSize: 50 }}
    //                     />
    //                   </p>
    //                   <p className='midCls'>Follow us.</p>
    //                   <p className='botCls'><InstagramIcon /> <FacebookIcon /> <TwitterIcon /> <LinkedInIcon /></p>
    //                   <p className='botCls'>Google Map</p>
    //                 </div>
    //               </Col>
    //               <Col className="flexBox" xs={12} md={6} sm={12}>
    //                 <div>
    //                   <p className='topCls'>
    //                     <LocationOnOutlinedIcon
    //                       sx={{ fontSize: 50 }}
    //                     />
    //                   </p>
    //                   <p className='midCls'>ADDRESS : </p>
    //                   <p className='botCls'>#1023,2nd Floor, Thangameenatchi Complex, Near Old Bus Stand, Kochi-Madurai-Tondi Point Rd, Theni, Tamil Nadu 625531</p>
    //                 </div>
    //               </Col>
    //             </Row>
    //             <Row>
    //               <Col className="flexBox" xs={12} md={6} sm={12}>
    //                 <div>
    //                   <p className='topCls'>
    //                     <EmailOutlinedIcon
    //                       sx={{ fontSize: 50 }}
    //                     />
    //                   </p>
    //                   <p className='midCls'>EMAIL : </p>
    //                   <p className='botCls'>contact@mspand.com</p>
    //                 </div>
    //               </Col>
    //               <Col className="flexBox" xs={12} md={6} sm={12}>
    //                 <div>
    //                   <p className='topCls'>
    //                     <LocalPhoneOutlinedIcon
    //                       sx={{ fontSize: 50 }}
    //                     />
    //                   </p>
    //                   <p className='midCls'>CALL US : </p>
    //                   <div className='botCls'>
    //                     <p style={{ padding: 0, margin: 0 }}>+91/0 - 97878 60208</p>
    //                     <p >+91/0 - 97878 60209</p>
    //                   </div>
    //                 </div>
    //               </Col>
    //             </Row>
    //           </Col>
    //           <Col xs={12} md={6} sm={12}>
    //             <div>
    //               <img
    //                 src="https://freepngimg.com/save/147846-professional-woman-business-png-file-hd/801x1023"
    //                 alt=""
    //                 width="80%"
    //                 height="560px"
    //                 className="flexBoxImg" />
    //             </div>
    //           </Col>
    //         </Row>
    //       </Container>
    //     </div>
  );
};
